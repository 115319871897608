@tailwind base;
@tailwind components;
@tailwind utilities;
.hero {
  width: 100% !important;
  display: flex !important;
  flex-direction: row;
  background: red !important;
}

